/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  //  overflow: auto;
  // pointer-events: auto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Poprawka nieprawidłowego wyświetlania ramki i focus'a gdy jednocześnie korzystamy z Angular Material i Tailwind */
.mdc-notched-outline__notch {
  border-right: none;
}

/* Poprawka nieprawidłowego pozycjonowania ikony na mat-icon-button */
[mat-icon-button] {
  line-height: normal;
}

.fap-view-container {
  @apply flex w-full h-full;
}

.fap-list-view-layout {
  @apply flex flex-col w-full h-full;
}

.fap-view-layout-b {
  @apply flex flex-col w-full h-full;
}

.fam-h-24 {
  height: 5rem;
  min-height: 5rem;
}

.fam-c-full {
  height: calc(100% - 5rem);
}

.fap-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.mat-paginator-sticky {
  @apply border-t border-gray-400;
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.fam-switch {
  transition: transform 0.2s;
}

.fam-rotated {
  transform: rotate(180deg);
}

.fam-rotate {
    transform: scale(5);
    animation: rotateSpinner 800ms linear infinite;
    @apply text-gray-800;
    opacity: 0.5;
}

@keyframes rotateSpinner {
	to {
		transform: scale(5) rotate(-360deg);
	}
}

.fam-rotate-form-field {
    animation: rotateSpinnerFormField 800ms linear infinite;
    @apply text-gray-800;
    opacity: 0.5;
}

@keyframes rotateSpinnerFormField {
	to {
		transform: rotate(-360deg);
	}
}

mat-selection-list.border {
  border-color: var(--mdc-outlined-text-field-outline-color)
}

.fam-invalid mat-selection-list.border {
  border-color: var(--mat-form-field-error-text-color)
}

.fam-label {
  display: block;
}

.fam-invalid .fam-label {
  color: var(--mat-form-field-error-text-color)
}

mat-selection-list + mat-error {
  visibility: hidden;
}

.fam-invalid  mat-selection-list + mat-error {
  visibility: visible;
}

.fam-full-screen {
  background-color: #faf9fd;
  @apply fixed inset-0 flex flex-col justify-center items-center
}

.fam-compound-view-container {
    @apply flex flex-col justify-between h-full overflow-hidden
}

.fam-compound-view-header {
    @apply flex justify-between border-b border-gray-400 fam-h-24 items-center
}

.fam-compound-view-header > h1 {
    @apply p-4 text-3xl grow-0
}

.fam-compound-view-header > .fam-header-info {
    @apply flex items-center p-4
}

.fam-compound-view-content {
    height: calc(100% - 5rem);
    @apply flex grow w-full justify-between
}

.fam-compound-view-menu {
    /* border-color: #3f4948; */
    @apply min-w-80 w-80 border-r border-gray-400 p-4
}

.fam-compound-view-outlet {
    @apply flex flex-col grow
}

.fam-config-container {
 	@apply px-8 pt-2 mb-24
}

.fam-config-section-header {
  	@apply pb-2 text-xl mt-12 mb-6 border-b border-gray-400 flex justify-between items-center w-full
}

.fam-config-section-header:first-of-type {
    @apply mt-0
}

mat-icon.fam-icon,
mat-datepicker-toggle button {
  color: rgb(0, 92, 187) !important;
}
/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
mat-icon.fam-icon svg {
    fill: rgb(0, 92, 187) !important;
}