/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  //  overflow: auto;
  // pointer-events: auto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Poprawka nieprawidłowego wyświetlania ramki i focus'a gdy jednocześnie korzystamy z Angular Material i Tailwind */
.mdc-notched-outline__notch {
  border-right: none;
}

/* Poprawka nieprawidłowego pozycjonowania ikony na mat-icon-button */
[mat-icon-button] {
  line-height: normal;
}

.fap-view-container {
  @apply flex w-full h-full;
}

.fap-list-view-layout {
  @apply flex flex-col w-full h-full;
}

.fap-view-layout-b {
  @apply flex flex-col w-full h-full;
}

.fam-h-24 {
  height: 5rem;
  min-height: 5rem;
}

.fam-c-full {
  height: calc(100% - 5rem);
}

.fap-cut {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.mat-paginator-sticky {
  @apply border-t;
  border-color: #3f4948;
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.fam-switch {
  transition: transform 0.2s;
}

.fam-rotated {
  transform: rotate(180deg);
}

.fam-rotate {
  animation: rotateSpinner 800ms linear infinite;
}

@keyframes rotateSpinner {
	to {
		transform: rotate(360deg);
	}
}

mat-selection-list.border {
  border-color: var(--mdc-outlined-text-field-outline-color)
}

.fam-invalid mat-selection-list.border {
  border-color: var(--mat-form-field-error-text-color)
}

.fam-label {
  display: block;
}

.fam-invalid .fam-label {
  color: var(--mat-form-field-error-text-color)
}

mat-selection-list + mat-error {
  visibility: hidden;
}

.fam-invalid  mat-selection-list + mat-error {
  visibility: visible;
}

.fam-full-screen {
  background-color: #191c1c;
  @apply fixed inset-0 flex flex-col justify-center items-center
}
